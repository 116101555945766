import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';
import {
  GET_ZIP_CODE_LIST,
  DELETE_ZIP_CODE,
  GET_ZIP_CODE,
  MANAGE_ZIP_CODE,
  RESET_ZIP_CODE,
  RESET_ZIP_CODE_LIST,
  RESET,
} from 'constants/actionTypes';

const INIT_STATE_LIST = {
  data: [],
  meta: { pagination: { total: 0 } },
  loading: false,
  isDeleting: false,
  deletedSuccess: false,
  deletedFailed: false,
};

const ZipCodeList = (state = INIT_STATE_LIST, action) => {
  switch (action.type) {
    case GET_ZIP_CODE_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_ZIP_CODE_LIST):
      return { ...state, data: action.payload.data, meta: action.payload.meta, loading: false };
    case FAILED(GET_ZIP_CODE_LIST):
      return { ...state, loading: false };
    case DELETE_ZIP_CODE:
      return { ...state, isDeleting: true };
    case SUCCESS(DELETE_ZIP_CODE):
      return { ...state, isDeleting: false, deletedSuccess: true };
    case FAILED(DELETE_ZIP_CODE):
      return { ...state, isDeleting: false, deletedFailed: true };
    case RESETED(DELETE_ZIP_CODE):
      return { ...state, isDeleting: false, deletedSuccess: false, deletedFailed: false };
    case RESET:
    case RESET_ZIP_CODE_LIST:
      return INIT_STATE_LIST;
    default:
      return state;
  }
};

const INIT_STATE_ZIP_CODE = {
  data: {
    id: '',
    zipCode: '',
    state: '',
    stateAbbreviation: '',
    county: '',
    countyFips: '',
    rateArea: '',
    routes: [],
  },
  success: false,
  error: false,
  isSubmitting: false,
  errors: null,
};

const ZipCode = (state = INIT_STATE_ZIP_CODE, action) => {
  switch (action.type) {
    case GET_ZIP_CODE:
      return {
        ...state,
        success: false,
        loading: true
      };
    case SUCCESS(GET_ZIP_CODE):
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };
    case FAILED(GET_ZIP_CODE):
      return {
        ...state,
        loading: false,
        error: true
      };
    case MANAGE_ZIP_CODE:
      return {
        ...state,
        errors: null,
        isSubmitting: true
      };
    case SUCCESS(MANAGE_ZIP_CODE):
      return {
        ...state,
        data: action.payload.data,
        isSubmitting: false,
        success: true,
        loading: false,
        errors: null,
      };
    case FAILED(MANAGE_ZIP_CODE):
      return {
        ...state,
        errors: action.payload,
        isSubmitting: false,
        success: false
      };
    case RESET_ZIP_CODE:
    case RESET:
      return INIT_STATE_ZIP_CODE;
    default:
      return state;
  }
};

export default combineReducers({
  ZipCodeList,
  ZipCode,
});

export const getZipCodeListSelector = createSelector(
    [state => state.ZipCode.ZipCodeList.data],
    data => data
);

export const getZipCodeSelector = createSelector(
    [state => state.ZipCode.ZipCode.data],
    data => data
);

export const getZipCodeLoadingSelector = createSelector(
    [state => state.ZipCode.ZipCode.loading],
    loading => loading
);

export const getZipCodeListLoadingSelector = createSelector(
    [state => state.ZipCode.ZipCodeList.loading],
    loading => loading
);

export const getZipCodeSuccessSelector = createSelector(
    [state => state.ZipCode.ZipCode.success],
    success => success
);

export const getZipCodeListMetaSelector = createSelector(
    [state => state.ZipCode.ZipCodeList.meta],
    meta => meta
);

export const getZipCodeErrorSelector = createSelector(
    [state => state.ZipCode.ZipCode.errors],
    errors => errors
);

export const getZipCodeIsSubmittingSelector = createSelector(
    [state => state.ZipCode.ZipCode.isSubmitting],
    isSubmitting => isSubmitting
);

export const getZipCodeIsDeletingSelector = createSelector(
    [state => state.ZipCode.ZipCodeList.isDeleting],
    isDeleting => isDeleting
);

export const getZipCodeDeletedSuccessSelector = createSelector(
    [state => state.ZipCode.ZipCodeList.deletedSuccess],
    deletedSuccess => deletedSuccess
);

export const getZipCodeDeletedFailedSelector = createSelector(
    [state => state.ZipCode.ZipCodeList.deletedFailed],
    deletedFailed => deletedFailed
);
